import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useRouter } from 'next/router'
import { CaretDown } from 'phosphor-react'
import React from 'react'

const LanguageSwitcher = () => {
  const router = useRouter()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const changeLanguageFR = () => {
    router.push(router.asPath, router.asPath, { locale: 'fr' })
    i18n.activate('fr')
  }
  const changeLanguageDE = () => {
    router.push(router.asPath, router.asPath, { locale: 'de' })
    i18n.activate('de')
  }

  const handleLanguageMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        aria-controls={open ? 'language-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleLanguageMenuClick}
        style={{ color: 'white' }}
        endIcon={<CaretDown />}
      >
        {t`languageMenu.language`}
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={changeLanguageDE}>DE</MenuItem>
        <MenuItem onClick={changeLanguageFR}>FR</MenuItem>
      </Menu>
    </div>
  )
}

export default LanguageSwitcher
