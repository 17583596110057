import { useFetchUser } from '@/components/user-hook'
import { FleetOwner } from '@/domain/fleet/types'
import { Garage } from '@/domain/garage/types'
import { isDevelopmentSystem } from '@/utils/configuration'
import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import Link from 'next/link'
import { SignOut } from 'phosphor-react'
import LanguageSwitcher from '../language-switcher'
import styles from './header.module.css'
import Logo from './logo'
interface Props {
  garage?: Garage
  fleetOwner?: FleetOwner
}
const Header = (props: Props) => {
  const { user, loading } = useFetchUser()

  return (
    <header className={classNames([styles.header, isDevelopmentSystem ? styles.testSystemHeader : ''])}>
      <Link href="/">
        <a>
          <Logo width={120} height={45} />
        </a>
      </Link>
      <div className={styles.testsystemtext}>{isDevelopmentSystem ? 'Testsystem' : ''}</div>
      <div style={{ flex: 1 }} className={styles.headertext}>
        {props.garage && (
          <Link href={`/garages/${props.garage.garageNumber}`}>
            <a>{props.garage.garageName}</a>
          </Link>
        )}
        {props.fleetOwner && (
          <Link href={`/fleetOwner/${props.fleetOwner.fleetOwnerId}`}>
            <a>{props.fleetOwner.name}</a>
          </Link>
        )}
      </div>
      {!loading && <LanguageSwitcher />}
      <div className={styles.headertext}>
        {!loading && !user && (
          <a href="/api/auth0/login">
            <Trans>Anmelden</Trans>
          </a>
        )}
        {!loading && user && (
          <>
            <div className={styles.username}>{user.name}</div>
            <div className={styles.logoutIcon}>
              <a href="/api/auth0/logout">
                <SignOut size={24} />
              </a>
            </div>
          </>
        )}
      </div>
    </header>
  )
}

export default Header
